import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import Command from '@ckeditor/ckeditor5-core/src/command'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'
import { Collection } from '@ckeditor/ckeditor5-utils'
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils'
import {
  Model,
  createDropdown,
  addListToDropdown,
} from '@ckeditor/ckeditor5-ui'
import closeIcon from './closeIcon.svg'

export class CustomTagCommand extends Command {
  execute({ value }) {
    const editor = this.editor
    const selection = editor.model.document.selection

    editor.model.change((writer) => {
      const placeholder = writer.createElement('customTag', {
        ...Object.fromEntries(selection.getAttributes()),
        field: value,
      })

      editor.model.insertContent(placeholder)
      writer.setSelection(placeholder, 'on')
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection

    const isAllowed = model.schema.checkChild(
      selection.focus.parent,
      'customTag'
    )

    this.isEnabled = isAllowed
  }
}

export class CustomTagInline extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add('customTag', new CustomTagCommand(this.editor))

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass('customTag')
      )
    )

    this.editor.config.define('customTags', {})
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('customTag', {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributesOf: '$text',
      allowAttributes: ['field'],
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['customTag'],
      },
      model: (viewElement, { writer: modelWriter }) => {
        const field = viewElement.getChild(0).data.slice(2, -2)
        return modelWriter.createElement('customTag', { field })
      },
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'customTag',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = this.createPlaceholderView(
          true,
          modelItem,
          viewWriter
        )
        return toWidget(widgetElement, viewWriter)
      },
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'customTag',
      view: (modelItem, { writer: viewWriter }) =>
        this.createPlaceholderView(false, modelItem, viewWriter),
    })
  }

  createPlaceholderView(isEditor, modelItem, viewWriter) {
    const list = this.editor.config.get('customTags')
    const field = modelItem.getAttribute('field')
    const innerText = isEditor ? list[field] || 'Invalid Tag' : `{{${field}}}`
    const text = viewWriter.createText(innerText)
    const children = [text]

    const placeholderView = viewWriter.createContainerElement(
      'span',
      {
        class: 'customTag',
      },
      {
        isAllowedInsideAttributeElement: true,
      }
    )

    if (isEditor) {
      const removeButton = viewWriter.createRawElement(
        'span',
        {
          class: 'customTag__button',
        },
        (el) => {
          el.innerHTML = closeIcon
          el.onclick = () => {
            this.editor.model.change((writer) => writer.remove(modelItem))
          }
        }
      )

      children.push(removeButton)
    }

    viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), children)

    return placeholderView
  }
}

export class CustomTagUI extends Plugin {
  init() {
    const editor = this.editor
    const list = editor.config.get('customTags') || {}
    const { t } = editor

    editor.ui.componentFactory.add('customTags', (locale) => {
      const dropdown = createDropdown(locale)
      const itemDefinitions = new Collection()

      Object.entries(list).forEach(([k, v]) => {
        const def = {
          type: 'button',
          model: new Model({
            label: t(v),
            class: k,
            withText: true,
          }),
        }

        itemDefinitions.add(def)
      })

      dropdown.panelPosition = 's'

      dropdown.buttonView.set({
        label: t('Flettefelt'),
        tooltip: true,
        withText: true,
      })

      addListToDropdown(dropdown, itemDefinitions)

      this.listenTo(dropdown, 'execute', (evt) => {
        editor.execute('customTag', { value: evt.source.class })
        editor.editing.view.focus()
      })

      return dropdown
    })
  }
}
